<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      scrollable
      fullscreen
      persistent
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          {{ $t("e_test.msg_excel.title_dialog_upload") }}
          <div style="position: absolute; right: 25px;">
            <v-icon :disabled="loading" @click="close">mdi-close</v-icon>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4">
          <v-alert
            v-if="bodyRequest.length > 0"
            dense
            type="warning"
            border="left"
          >
            {{ $t("e_test.msg_excel.info") }}
          </v-alert>
          <v-alert dense type="info" border="left">
            <ul>
              <li>
                {{ questionSuccess.length }}
                {{ $t("e_test.msg_excel.info_success") }}
              </li>
              <li>
                {{ bodyRequest.length }}
                {{ $t("e_test.msg_excel.info_failed") }}
              </li>
              <li>
                Total data {{ questionSuccess.length + bodyRequest.length }}
              </li>
            </ul>
          </v-alert>
          <v-data-table
            :headers="tableHeaders"
            :items="bodyRequest"
            disable-pagination
            hide-default-footer
            class="elevation-0"
          >
            <template #item.periode="{ item }">
              <v-edit-dialog
                :cancel-text="$t('app.cancel')"
                :save-text="$t('app.save')"
                :return-value.sync="item.periode"
                large
                transition="fade-transition"
              >
                <v-btn color="info" text>{{ item.periode }}</v-btn>
                <template #input>
                  <v-select
                    v-model="item.periode"
                    :items="generateYears()"
                    item-text="name"
                    item-value="id"
                    single-line
                  />
                </template>
              </v-edit-dialog>
            </template>
            <template #item.grade="{ item }">
              <v-edit-dialog
                :cancel-text="$t('app.cancel')"
                :save-text="$t('app.save')"
                :return-value.sync="item.grade"
                large
                transition="fade-transition"
              >
                <v-btn color="info" text>{{ item.grade }}</v-btn>
                <template #input>
                  <v-select
                    v-model="item.grade"
                    :items="listGrade()"
                    single-line
                  />
                </template>
              </v-edit-dialog>
            </template>
            <template #item.semester="{ item }">
              <v-edit-dialog
                :cancel-text="$t('app.cancel')"
                :save-text="$t('app.save')"
                :return-value.sync="item.semester"
                large
                transition="fade-transition"
              >
                <v-btn color="info" text>{{ item.semester }}</v-btn>
                <template #input>
                  <v-select
                    v-model="item.semester"
                    :items="[1, 2]"
                    single-line
                  />
                </template>
              </v-edit-dialog>
            </template>
            <template #item.lesson="{ item }">
              <v-edit-dialog
                :cancel-text="$t('app.cancel')"
                :save-text="$t('app.save')"
                :return-value.sync="item.lesson"
                large
                transition="fade-transition"
              >
                <v-btn color="info" text small>
                  <span
                    class="d-inline-block text-truncate"
                    style="max-width: 200px"
                  >
                    {{ item.lesson }}
                  </span>
                </v-btn>
                <template #input>
                  <v-textarea
                    v-model="item.lesson"
                    :rules="[v => !!v || $t('app.required')]"
                    single-line
                    auto-grow
                    rows="1"
                  />
                </template>
              </v-edit-dialog>
            </template>
            <template #item.point="{ item }">
              <v-edit-dialog
                :cancel-text="$t('app.cancel')"
                :save-text="$t('app.save')"
                :return-value.sync="item.point"
                large
                transition="fade-transition"
              >
                <v-btn color="info" text>{{ item.point }}</v-btn>
                <template #input>
                  <v-autocomplete
                    v-model="item.point"
                    :items="listPoints()"
                    single-line
                  />
                </template>
              </v-edit-dialog>
            </template>
            <template #item.error="{ item }">
              <span class="error--text">
                {{ item.error }}
              </span>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider v-if="bodyRequest.length > 0 || loading" />
        <v-card-actions v-if="bodyRequest.length > 0 || loading">
          <v-spacer />
          <v-btn
            :loading="loading"
            class="gradient-primary"
            outlined
            small
            dark
            depressed
            @click="save"
          >
            Upload {{ bodyRequest.length }} data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { setQuestionBankGlobalExcel } from "@/api/admin/academic/eTest";

export default {
  props: {
    dialog: Boolean,
    questionErr: Array,
    questionSuccess: Array
  },
  watch: {
    dialog(value) {
      if (value) {
        if (this.questionErr.length) {
          this.bodyRequest = this.questionErr;
        } else {
          this.snackBar(true, this.$i18n.t("e_test.msg_excel.success_upload"));
          this.close();
        }
      }
    }
  },
  data() {
    return {
      bodyRequest: [],
      loading: false,
      loadingUploadSuccess: false,
      tableHeaders: [
        { text: this.$i18n.t("e_test.period"), value: "periode" },
        {
          text: this.$i18n.t("app.grade"),
          value: "grade"
        },
        {
          text: "Semester",
          value: "semester"
        },
        {
          text: this.$i18n.t("app.subjects"),
          value: "lesson"
        },
        {
          text: this.$i18n.t("e_test.question_type"),
          value: "type"
        },
        {
          text: this.$i18n.t("e_test.question"),
          value: "question"
        },
        {
          text: this.$i18n.t("e_test.total_weight"),
          value: "point"
        },
        {
          text: this.$i18n.t("e_test.reason_failed"),
          value: "error"
        }
      ]
    };
  },
  methods: {
    async save() {
      this.loading = true;
      const body = JSON.parse(JSON.stringify(this.bodyRequest));
      this.bodyRequest = [];
      const questionError = body.map(async json => {
        const response = await setQuestionBankGlobalExcel(json);
        if (response.data.code) {
          this.addSuccess(json);
        } else {
          return { ...json, error: response.data.message };
        }
      });
      // filter failed data
      this.bodyRequest = (await Promise.all(questionError)).filter(
        item => typeof item === "object" && item !== null
      );
      // is success
      if (this.bodyRequest.length === 0) {
        this.snackBar(true, this.$i18n.t("e_test.msg_excel.success_upload"));
        this.close();
      }
      this.loading = false;
    },
    addSuccess(item) {
      this.$emit("addSuccess", item);
    },
    close() {
      this.bodyRequest = [];
      this.$emit("close");
    },
    listGrade() {
      let result = [];
      for (let i = 1; i <= 12; i++) {
        result.push(i);
      }
      return result;
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    listPoints() {
      let result = [];
      for (let i = 1; i <= 100; i++) {
        result.push(i);
      }
      return result;
    },
    generateYears() {
      let currentYear = new Date().getFullYear();
      let years = [];
      let startYear = 2000;
      while (startYear <= currentYear) {
        let year = startYear++;
        years.push({ name: `${year}`, id: year });
      }
      return years.reverse();
    }
  }
};
</script>
